import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "replay-detail"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v("反馈信息")]), _c("a-card", [_c("a-form-model", {
    attrs: {
      layout: "inline",
      labelAlign: "left"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "反馈人ID："
    }
  }, [_c("a-input", {
    attrs: {
      disabled: "",
      value: _vm.id
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "反馈人昵称："
    }
  }, [_c("a-input", {
    attrs: {
      disabled: "",
      value: _vm.feedbacknickname
    }
  })], 1)], 1)], 1), _c("p", {
    staticClass: "title mt-20"
  }, [_vm._v("反馈详情")]), _c("a-card", {
    staticClass: "mt-20"
  }, [_c("div", {
    ref: "scrollBox",
    staticClass: "scroll",
    on: {
      scroll: _vm.scrollHandler
    }
  }, [_c("div", {
    staticClass: "flex-col"
  }, [_vm._l(_vm.list, function (item, index) {
    return [item.type == 1 ? _c("questionVue", {
      key: index,
      staticStyle: {
        width: "80%",
        "min-width": "980px"
      },
      attrs: {
        item: item
      }
    }) : _c("relpayVue", {
      key: index,
      staticStyle: {
        width: "60%",
        "min-width": "720px",
        "align-self": "flex-end"
      },
      attrs: {
        item: item,
        "is-view": _vm.isView
      },
      on: {
        edit: _vm.editHandler,
        del: _vm.deleteHandler
      }
    })];
  })], 2)])]), !_vm.isView ? _c("div", {
    staticClass: "mt-20"
  }, [_c("a-textarea", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      placeholder: "请输入回复内容",
      "auto-size": {
        minRows: 3,
        maxRows: 6
      }
    },
    model: {
      value: _vm.replymessage,
      callback: function callback($$v) {
        _vm.replymessage = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "replymessage"
    }
  }), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["im:feedBack:reply"],
      expression: "['im:feedBack:reply']"
    }],
    staticClass: "mt-20",
    staticStyle: {
      "margin-left": "32px",
      "vertical-align": "top"
    },
    attrs: {
      type: "primary",
      loading: _vm.loadingReply
    },
    on: {
      click: _vm.replyHandler
    }
  }, [_vm._v("回复")])], 1) : _vm._e(), _c("a-modal", {
    attrs: {
      title: "回复修改",
      okText: "提交",
      cancelText: "取消",
      cancelButtonProps: {
        style: {
          marginRight: "32px"
        }
      }
    },
    on: {
      cancel: function cancel($event) {
        _vm.visibleEdit = false;
      },
      ok: _vm.confirmHandler
    },
    model: {
      value: _vm.visibleEdit,
      callback: function callback($$v) {
        _vm.visibleEdit = $$v;
      },
      expression: "visibleEdit"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.editData.feedbackdesc,
      callback: function callback($$v) {
        _vm.$set(_vm.editData, "feedbackdesc", $$v);
      },
      expression: "editData.feedbackdesc"
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "回复删除",
      okText: "删除",
      cancelText: "取消",
      cancelButtonProps: {
        style: {
          marginRight: "32px"
        }
      }
    },
    on: {
      cancel: function cancel($event) {
        _vm.visibleDelete = false;
      },
      ok: _vm.confirmDelete
    },
    model: {
      value: _vm.visibleDelete,
      callback: function callback($$v) {
        _vm.visibleDelete = $$v;
      },
      expression: "visibleDelete"
    }
  }, [_c("span", [_vm._v("确认删除回复“" + _vm._s(_vm.deleteData.feedbackdesc) + "”吗？")])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };