export default {
  data: function data() {
    return {
      typeList: [{
        id: '0',
        name: '未处理'
      }, {
        id: '1',
        name: '已处理'
      }],
      previewVisible: false,
      previewImage: ''
    };
  },
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {
          type: 1,
          status: 1
        };
      }
    }
  },
  methods: {
    previewHandler: function previewHandler(url) {
      this.previewImage = url;
      this.previewVisible = true;
    }
  }
};