import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import { IMApi } from "@/api";
import questionVue from "./components/question.vue";
import relpayVue from "./components/replay.vue";
import throttle from "lodash/throttle";
export default {
  data: function data() {
    return {
      feedbacknickname: '',
      replymessage: "",
      editData: {},
      visibleEdit: false,
      visibleDelete: false,
      deleteData: {},
      isView: false,
      id: "",
      checkNo: "",
      page: 1,
      size: 10,
      list: [],
      isloading: false,
      loadEnd: false,
      loadingReply: false
    };
  },
  components: {
    questionVue: questionVue,
    relpayVue: relpayVue
  },
  mounted: function mounted() {
    this.id = this.$route.query.id || "";
    this.checkNo = this.$route.query.number || "";
    this.isView = !this.$route.query.type || this.$route.query.type == "view";
    this.getDetail();
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      if (this.isloading) return;
      this.isloading = true;
      IMApi.feedBackDetails({
        feedbackbatchno: this.checkNo,
        userid: this.checkNo ? "" : this.id,
        page: this.page,
        size: this.size
      }).then(function (res) {
        // console.log(res);
        // type 1-表示反馈类型，2-是回复类型
        if (!_this.feedbacknickname) _this.feedbacknickname = res[0].feedbacknickname;
        if (res.length) {
          _this.list = _this.list.concat(res);
          _this.page++;
        } else {
          _this.loadEnd = true;
        }
      }).catch(function (err) {}).finally(function () {
        _this.isloading = false;
        _this.loadingReply = false;
      });
    },
    replyHandler: function replyHandler() {
      var params = {
        feedbackbatchno: this.checkNo,
        feedbackuid: this.id,
        replymessage: this.replymessage
      };
      this.reply(params);
    },
    reply: function reply(params) {
      var _this2 = this;
      // params.id存在表示为回复修改
      IMApi.feedBackReply(params).then(function (res) {
        // console.log(res);
        if (params.id) {
          _this2.visibleEdit = false;
          _this2.list.map(function (value) {
            if (value.id == data.id) {
              _this2.$set(value, 'feedbackdesc', params.replymessage);
            }
          });
        } else {
          if (!_this2.checkNo) _this2.checkNo = res.feedbackbatchno;
          _this2.page = 1;
          _this2.list = [];
          _this2.replymessage = '';
          _this2.loadEnd = false;
          _this2.loadingReply = true;
          _this2.getDetail();
        }
      }).catch(function (err) {});
    },
    editHandler: function editHandler(value) {
      this.editData = value;
      this.visibleEdit = true;
    },
    deleteHandler: function deleteHandler(value) {
      this.deleteData = value;
      this.visibleDelete = true;
    },
    confirmDelete: function confirmDelete() {
      var _this3 = this;
      IMApi.feedBackDelete({
        id: this.deleteData.id
      }).then(function (res) {
        _this3.list = _this3.list.filter(function (val) {
          return val.id != _this3.deleteData.id;
        });
        _this3.visibleDelete = false;
      }).catch(function (err) {});
    },
    confirmHandler: function confirmHandler() {
      var params = {
        feedbackbatchno: this.checkNo,
        feedbackuid: this.id,
        replymessage: this.editData.feedbackdesc,
        id: this.editData.id
      };
      this.reply(params);
    },
    scrollHandler: throttle(function () {
      var clientHeight = this.$refs.scrollBox.clientHeight;
      var scrollHeight = this.$refs.scrollBox.scrollHeight;
      var scrollTop = this.$refs.scrollBox.scrollTop;
      if (scrollHeight - scrollTop < 3 / 2 * clientHeight && !this.loadEnd) {
        this.getDetail();
      }
    }, 100)
  }
};