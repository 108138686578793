import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "question-bg"
  }, [_c("a-form-model", {
    attrs: {
      layout: "inline",
      labelAlign: "left"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "类型："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.item.feedbacktypename))])], 1), _c("a-form-item", {
    staticStyle: {
      width: "310px"
    },
    attrs: {
      label: "反馈时间(UTC)："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.$moment(Number(_vm.item.feedbacktime * 1000)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss")))])], 1), _c("a-form-item", {
    attrs: {
      label: "状态："
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      "default-value": {
        key: "lucy"
      },
      disabled: ""
    },
    model: {
      value: _vm.item.feedbackstatus,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "feedbackstatus", $$v);
      },
      expression: "item.feedbackstatus"
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "联系方式："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.item.feedbackcontact))])], 1), _c("a-row", [_c("a-form-item", {
    attrs: {
      label: "反馈描述："
    }
  }, [_c("a-textarea", {
    staticStyle: {
      "min-width": "560px"
    },
    attrs: {
      disabled: "",
      value: _vm.item.feedbackdesc,
      autoSize: ""
    }
  })], 1)], 1), _c("a-row", [_c("a-form-item", {
    attrs: {
      label: "图片："
    }
  }, [_c("div", {
    staticClass: "flex-wrap"
  }, _vm._l(_vm.item.feedbackimgs, function (Itm, idx) {
    return _c("img", {
      key: idx,
      staticClass: "item-img",
      attrs: {
        src: Itm,
        title: "举报图片"
      },
      on: {
        click: function click($event) {
          return _vm.previewHandler(Itm);
        }
      }
    });
  }), 0)])], 1), _c("a-form-item", {
    attrs: {
      label: "IP地址："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.item.ipaddr))])], 1), _c("a-form-item", {
    attrs: {
      label: "设备信息："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.item.devicename))])], 1), _c("a-form-item", {
    attrs: {
      label: "设备机型："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.item.devicetype))])], 1), _c("a-form-item", {
    attrs: {
      label: "app版本："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.item.appversion))])], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: function closePreviewpic($event) {
        _vm.previewVisible = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };