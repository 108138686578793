export default {
  daa: function daa() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isView: {
      type: Boolean,
      default: true
    }
  },
  methods: {}
};