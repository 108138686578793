import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "relpy"
  }, [_c("a-form", {
    attrs: {
      layout: "inline"
    }
  }, [_c("a-row", [_c("a-form-item", {
    attrs: {
      label: "回复人："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.item.feedbacknickname))])], 1), _c("a-form-item", {
    attrs: {
      label: "回复时间(UTC)："
    }
  }, [_c("a-button", {
    attrs: {
      disabled: ""
    }
  }, [_vm._v(_vm._s(_vm.$moment(Number(_vm.item.feedbacktime * 1000)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss")))])], 1), !_vm.isView ? _c("div", {
    staticClass: "btn-box"
  }, [_c("span", {
    staticClass: "link",
    on: {
      click: function click($event) {
        return _vm.$emit("edit", _vm.item);
      }
    }
  }, [_vm._v("编辑")]), _c("span", {
    staticClass: "link",
    staticStyle: {
      color: "#ff4d4f"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("del", _vm.item);
      }
    }
  }, [_vm._v("删除")])]) : _vm._e()], 1), _c("a-form-item", {
    attrs: {
      label: "回复："
    }
  }, [_c("a-textarea", {
    staticStyle: {
      "min-width": "560px"
    },
    attrs: {
      disabled: "",
      autoSize: false,
      value: _vm.item.feedbackdesc
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };